<template>
  <div class="list-chart">
    <CartItem v-for="(cart, index) in carts" :cart="cart" :key="index" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CartItem from '@/components/molecules/CartItem'
export default {
  name: 'CartList',
  components: {
    CartItem
  },
  computed: mapState('cart', ['carts'])
}
</script>

<style scoped></style>
