import Vue from 'vue';

export default {
  toastError: (param) => {
    Vue.$toast.error(param, {
      duration: 15000,
    });
  },
  toastSuccess: (param) => {
    Vue.$toast.success(param);
  },
};
