<template>
  <div class="row">
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <CardInfoHistory
        background="bg-1"
        title="Total Orders"
        dollarSignShow="false"
        total=10
      />
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mb-3">
      <CardInfoHistory
        background="bg-2"
        title="Total Orders"
        dollarSignShow="true"
        total=10
      />
    </div>
    <div class="col-lg-4 col-md-12 col-sm-12 mb-3">
      <CardInfoHistory
        background="bg-3"
        title="Total Orders"
        dollarSignShow="true"
        total=10
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardInfoHistory from '@/components/atoms/CardInfoHistory'
export default {
  name: 'ListInfoHistory',
  components: {
    CardInfoHistory
  },
  computed: {
    ...mapGetters('history', [
      'getIncomeToday',
      'getIncomeYesterday',
      'getThisYearIncome',
      'getOrdersToday',
      'getOrdersYesterday'
    ]),
    difference() {
      let result = Math.floor(
        ((this.getIncomeToday - this.getIncomeYesterday) * 100) /
          this.getIncomeYesterday
      )
      if (result >= 100) {
        result = 100
      }
      return result
    },
    checkIncomeStatus() {
      return this.getIncomeToday > this.getIncomeYesterday
    },
    checkOrderStatus() {
      return this.getOrdersToday > this.getOrdersYesterday
    }
  }
}
</script>

<style scoped>
</style>
