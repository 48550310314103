<template>
  <div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
export default {
  data() {
    return {
      sort: ['id', 'desc'],
      listOrders: [
        {
          name: 'Newest',
          ordSort: ['id', 'desc']
        },
        {
          name: 'Oldest',
          ordSort: ['id', 'asc']
        },
        {
          name: 'Name A-Z',
          ordSort: ['name', 'asc']
        },
        {
          name: 'Name Z-A',
          ordSort: ['name', 'desc']
        },
        {
          name: 'Cheapest',
          ordSort: ['price', 'asc']
        },
        {
          name: 'Expensive',
          ordSort: ['price', 'desc']
        }
      ]
    }
  },
  methods: {
    ...mapActions('product', ['getProducts']),
    ...mapMutations('product', ['CHANGE_ORDERING']),
    changeOrder() {
      this.product.currentPage = 1
      const order = {
        limit: this.roleId !== 3 ? 9 : 12,
        page: this.currentPage,
        order: this.sort[0],
        sort: this.sort[1],
        search: this.searchInputText
      }
      this.CHANGE_ORDERING(order)
      this.getProducts(order)
    }
  },
  computed: {
    ...mapState('product', [
      'currentPage',
      'totalPages',
      'searchInputText',
      'ordering'
    ]),
    ...mapState(['product']),
    ...mapState('auth', ['roleId'])
  }
}
</script>

<style scoped>
</style>
