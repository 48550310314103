<template>
  <div class="home">
    <b-container fluid>
      <b-row>
        <b-col md="12" class="px-0">
          <div class="content-left history pt-4 pr-4 pb-0">
            <Sidebar />
            <div class="main-left mb-2">
              <div class="row">
                <div class="col-md-12 mb-5 mt-3">
                  <StockManagementCard />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Sidebar from '@/components/layout/MainSidebar';
import StockManagementCard from '@/components/organisms/StockManagementCard';
// @ is an alias to /src
import '@/assets/css/style.css';
export default {
  name: 'Stock',
  components: {
    Sidebar,
    StockManagementCard,
  },
  data() {
    return {};
  },
};
</script>
