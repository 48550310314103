<template>
  <div class="box">
    <h1>Printd example</h1>

    <!-- <pre>
      import Printd from 'printd'

      const p = new Printd()

      p.print(this.$el, `.my_style { color: blue; }`)
    </pre> -->

    <button @click="print">Print this component</button>
  </div>
</template>

<script>
import Printd from 'printd'

export default {
  data() {
    return {
      d: new Printd(),
      cssText: `
        .box {
          font-family: sans-serif;
          width: 500px;
          border: solid 1px #ccc;
          text-align: center;
          padding: 1em;
          margin: 2em auto;
        }

        button {
          background-color: #f0f0f0;
          border: solid 1px #ccc;
          padding: 5px 10px;
          font-size: 12px;
        }

        pre {
          color: #c7254e;
        }
      `
    }
  },
  methods: {
    print() {
      this.d.print(this.$el, this.cssText)
    }
  }
}
</script>

<style>
.box {
  font-family: sans-serif;
  width: 500px;
  border: solid 1px #ccc;
  text-align: center;
  padding: 1em;
  margin: 2em auto;
}

button {
  background-color: #f0f0f0;
  border: solid 1px #ccc;
  padding: 5px 10px;
  font-size: 12px;
}

pre {
  color: #c7254e;
}
</style>
