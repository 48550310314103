/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable space-before-function-paren */
import Vue from 'vue';
import Auth from '@/apis/Auth';
import Api from '@/apis/Api';
import router from '@/router';

// state
const state = {
  token: localStorage.getItem('token') || null,
  idUser: localStorage.getItem('idUser') || null,
  customer_token: localStorage.getItem('customer_token') || null,
  customer: null,
};

// getters
const getters = {
  isLogin(state) {
    return state.token !== null;
  },

  getCustomer(state) {
    return state.customer;
  }
};

// actions
const actions = {
  async loginUser({ commit, dispatch }, dataLogin) {
    dispatch('changeIsLoading', true, { root: true });

    try {
      const response = await Auth.login(dataLogin);
      commit('LOGIN_USER', response.data);
      dispatch('changeIsLoading', false, { root: true });
      return response.data;
    } catch (error) {
      dispatch('changeIsLoading', false, { root: true });
      throw error.response.data;
    }
  },

  async loginCustomer({ commit, dispatch }, dataLogin) {
    dispatch('changeIsLoading', true, { root: true });

    try {
      const response = await Auth.customerLogin(dataLogin);
      commit('LOGIN_CUSTOMER', response.data);
      dispatch('changeIsLoading', false, { root: true });
      return response.data;
    } catch (error) {
      dispatch('changeIsLoading', false, { root: true });
      throw error.response.data;
    }
  },

  async logoutUser({ commit, dispatch }) {
    dispatch('changeIsLoading', true, { root: true });

    try {
      await Auth.logout();
      commit('LOGOUT_USER');
      dispatch('cart/clearCartsWhenLogout', null, { root: true });
    } catch (error) {
      console.error('Logging out:', error);
    } finally {
      dispatch('changeIsLoading', false, { root: true });
    }
  },

  async logoutCustomer({ commit, dispatch }) {
    dispatch('changeIsLoading', true, { root: true });

    try {
      // await Auth.logout();
      commit('LOGOUT_CUSTOMER');
      // dispatch('cart/clearCartsWhenLogout', null, { root: true });
    } catch (error) {
      console.error('Logging out:', error);
    } finally {
      dispatch('changeIsLoading', false, { root: true });
    }
  },

  interceptorsRequest({ state }) {
    Api.interceptors.request.use(
      (config) => {
        try {
          const encryptedData = localStorage.getItem('data-time');
          const token = localStorage.getItem('token');
          const AUTHORIZATION_HEADER = 'Authorization';

          if (encryptedData && token) {
            const idUser = Vue.CryptoJS.AES.decrypt(
              encryptedData,
              process.env.VUE_APP_SECRET_KEY
            ).toString(Vue.CryptoJS.enc.Utf8);
            if (idUser) {
              state.idUser = idUser;
            }
            config.headers[AUTHORIZATION_HEADER] = `Bearer ${token}`;
          }

          return config;
        } catch (error) {
          console.error(error);
          return Promise.reject(error);
        }
      },
      (error) => {
        console.error(error);
        return Promise.reject(error);
      }
    );
  },

  interceptorsResponse({ commit, dispatch }) {
    Api.interceptors.response.use(
      function (response) {
        return response;
      },
      function (err) {
        const { error, status_code } = err.response.data;

        if (status_code === 401) {
          if (error === 'Token invalid') {
            commit('LOGOUT_USER');
            Vue.$toast.error('Opps... Your token is invalid!');
          } else if (error === 'Token expired') {
            Vue.$toast.error('Your session has expired');
            dispatch('logoutUser');
          }

          router.replace({
            name: 'Login',
          });
        } else if (status_code === 403 && error === 'Only admins can access') {
          Vue.$toast.error('Opps... You do not have permission!');
          router.replace({
            name: 'Home',
          });
        }

        return Promise.reject(err);
      }
    );
  },
};

// mutations

const mutations = {
  LOGIN_USER: (state, data) => {
    const encryptedId = Vue.CryptoJS.AES.encrypt(
      data.data.auth.id.toString(),
      process.env.VUE_APP_SECRET_KEY
    ).toString();
    const encryptedBranchId = Vue.CryptoJS.AES.encrypt(
      data.data.auth.branch_id.toString(),
      process.env.VUE_APP_SECRET_KEY
    ).toString();

    localStorage.setItem('data-time', encryptedId);
    localStorage.setItem('data-bId', encryptedBranchId);
    localStorage.setItem('branchId', data.data.auth.branch_id);


    localStorage.setItem('token', data.data.token);
    state.token = data.data.token;
    state.idUser = data.data.auth.id;
  },

  LOGIN_CUSTOMER: (state, data) => {
    localStorage.setItem('customer_token', data.data.token);
    state.customer = data.data.customer;
  },

  LOGOUT_USER: (state) => {
    localStorage.clear();
    state.idUser = {};
    state.token = null;
  },

  LOGOUT_CUSTOMER: (state) => {
    state.customer = null;
    localStorage.removeItem('customer_token');
  },

  CLEAR_CUSTOMER: (state) => {
    state.customer = null;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
