<template>
  <div v-if="detailProduct.display">
    <div class="m-1">
      <div @click="$emit('show-modal', 'product')" @click.prevent="SET_CATEGORY_PRODUCTS(detailProduct)"
        v-b-modal.modal-primary class="col-sm border bg-light cursor-pointer removeDefaultPadding"
        :class="{ active: checkActive.includes(detailProduct.id) }">
        <img :src="detailProduct.preview_url" :alt="detailProduct.name" class="img-fluid"
          style="height: 200px; width: 150px" />
        <p class="text-sm font-bold text-gray-700 py-2 text-center">
          {{ detailProduct.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ModalProduct from "./ModalProduct";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "CategoryItem",
  props: ["detailProduct", "checkActive"],
  methods: {
    ...mapMutations("category", ["SET_CATEGORY_PRODUCTS"]),
  },
  components: {
    ModalProduct,
  },
  computed: {
    ...mapGetters("category", ["categoryProducts"]),
  },
};
</script>

<style scoped>
.cursor-pointer:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.removeDefaultPadding {
  padding: 3px;
}
</style>
