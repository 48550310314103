<template>
  <div style="max-height: 100vh; overflow-y: auto; overflow-x: hidden">
    <b-card-group deck>
      <b-card isFooter noButtonHeader header="My Profile" header-tag="header">
        <div class="d-flex">
          <div class="mr-3"></div>
          <div class="detail">
            <p><b>Name:</b> {{ getDetailUser.data.name }}</p>
            <p><b>Email:</b> {{ getDetailUser.data.email }}</p>
            <p><b>Role:</b> {{ getDetailUser.data.roles[0].name }}</p>
            <p><b>Branch:</b> {{ getDetailUser.data.branch.name }}</p>
            <p><b>Phone:</b> {{ getDetailUser.data.branch.phone }}</p>
            <p><b>Address:</b> {{ getDetailUser.data.branch.address }}</p>
          </div>
        </div>
        <ButtonLogout classBtn="btn mr-2 my-1 btn-primary shadow">
          <span class="mr-2">Logout</span>
          <b-icon icon="box-arrow-in-left" scale="1" />
        </ButtonLogout>
      </b-card>

      <b-card header="POS configuration" header-tag="header">
        <div class="d-flex">
          <div class="mr-3"></div>
          <div class="detail">
            <b-form-group
              label="IP configuration"
              v-model="configSettings.ip"
              description="IP configuration of the pos machine"
            >
              <b-form-input v-model="configSettings.ip"></b-form-input>
            </b-form-group>
            <b-form-group label="Port" description="PORT configuration of the pos machine">
              <b-form-input v-model="configSettings.port"></b-form-input>
            </b-form-group>
            <b-button variant="info" @click="updateConfig">Save</b-button>
          </div>
        </div>
      </b-card>
    </b-card-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import mixins from '@/components/mixins/swal';
import ButtonLogout from '@/components/molecules/ButtonLogout';
import { getConfiguration, storeConfiguration } from '../../../apis/User';

export default {
  name: 'MyProfileCard',
  mixins: [mixins],
  components: {
    ButtonLogout,
  },
  data() {
    return {
      configSettings: {
        ip: null,
        port: null,
      },
    };
  },
  mounted() {
    this.configuration();
  },
  computed: mapGetters('user', ['getDetailUser']),
  methods: {
    ...mapActions('user', ['posConfiguration']),
    configuration() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        token: token,
      };
      getConfiguration(requestData)
        .then((response) => {
          if (response.status) {
            this.posConfiguration(response.data.data);
            this.configSettings.ip = response.data.data.ip;
            this.configSettings.port = response.data.data.port;
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    updateConfig() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        pos_configuration: {
          ip: this.configSettings.ip,
          port: this.configSettings.port,
        },
        token: token,
      };

      storeConfiguration(requestData)
        .then((response) => {
          if (response.status) {
            this.configuration();
            this.toastSuccess('Configuration updated successfully!');
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    }
  },
};
</script>

<style scoped></style>
