// State
const state = {
  categoryProducts: null,
  productAddons: null,
};

// Getters
const getters = {
  categoryProducts: (state) => {
    return state.categoryProducts;
  },

  productAddons: (state) => {
    return state.productAddons;
  },
};

// Mutations
const mutations = {
  SET_CATEGORY_PRODUCTS: (state, categoryProducts) => {
    state.categoryProducts = categoryProducts;
  },

  SET_PRODUCT_ADDONS: (state, productAddons) => {
    state.productAddons = productAddons;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
