import { render, staticRenderFns } from "./CardInfoHistory.vue?vue&type=template&id=f942ab26&scoped=true"
import script from "./CardInfoHistory.vue?vue&type=script&lang=js"
export * from "./CardInfoHistory.vue?vue&type=script&lang=js"
import style0 from "./CardInfoHistory.vue?vue&type=style&index=0&id=f942ab26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f942ab26",
  null
  
)

export default component.exports