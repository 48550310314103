<template>
  <div class="position-relative" style="height: 500px">
    <div class="p-2 d-flex justify-content-between text-gray-600 w-100">
      <b-button v-b-modal.modalCountrySelection size="md" variant="outline-primary">
        {{ selectedCountry.flag }} {{ selectedCountry.dial_code }}
      </b-button>
      <input
        ref="searchField"
        v-model="searchCustomerValue"
        placeholder="Enter Phone Number"
        type="number"
        class="form-control rounded border-2 border-primary bg-light p-2 w-75"
      />
      <button class="btn btn-primary mx-2" @click="handleLogin">Login</button>
    </div>
    <b-modal
      id="modalCountrySelection"
      size="lg"
      title="Select Country"
      ok-only
      centered
      hide-footer
    >
      <CountryModal
        :countries="countries"
        :selectedCountry="selectedCountry"
        @countrySelected="updateSelectedCountry"
      />
    </b-modal>
  </div>
</template>

<script>
import CountryList from 'country-list-with-dial-code-and-flag';
import mixins from '@/components/mixins/swal';
import CountryModal from './CountryModal.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LoginOrder',
  mixins: [mixins],
  components: {
    CountryModal,
  },
  data() {
    return {
      searchCustomerValue: '',
      countries: CountryList,
      selectedCountry: CountryList.find((country) => country.code == 'SG'),
    };
  },
  methods: {
    ...mapMutations('modal', ['SET_WHAT_MODAL']),
    ...mapActions('auth', ['loginCustomer']),
    handleLogin() {
      const phonenumber = this.selectedCountry.dial_code + '' + this.searchCustomerValue;
      const dataLogin = {
        phone: phonenumber,
      };
      this.loginCustomer(dataLogin)
        .then((response) => {
          this.toastSuccess(response.message);
        })
        .catch((error) => {
          this.toastSuccess(error.message);
          return;
        });

      this.SET_WHAT_MODAL('');
      // this.$emit('placeOrderAfterCustomerLogin');
    },
    updateSelectedCountry(country) {
      this.selectedCountry = country;
      this.$bvModal.hide('modalCountrySelection');
    },
  },
};
</script>
