import Api from './Api'
const END_POINT = 'inventory'

export default {
  all(branchId) {
    return Api.get(`${END_POINT}/${branchId}/category/index`, {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
  },
}

export async function getStock(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.get(`${data.branchId}/get-stock`, config)
}

export async function manageStock(data) {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
  }

  if (data.token != null) {
    headers = {
      ...headers,
      Authorization: `Bearer ${data.token}`,
    }
  }

  const config = {
    headers,
  }

  return Api.post(`${data.branchId}/manage-stock`, data.payload, config)
}
