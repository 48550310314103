<template>
  <div class="text-gray-600" style="height: 400px">
    <div class="container">
      <div class="d-flex align-items-center">
        <b-button v-b-modal.modalCountrySelection size="sm" variant="outline-primary" class="mr-2">
          {{ selectedCountry.flag }} {{ selectedCountry.dial_code }}
        </b-button>
        <vue-select
          style="width: 80%;"
          :options="countries"
          label=""
          :getOptionLabel="(option) => option.name"
          :value="selectedCountry"
          @input="handleCountrySelection"
        >
          <template slot="option" slot-scope="option">
            <div style="display: flex; justify-content: space-between; width: 100%;">
              <div>
                <span>{{ option.flag }}</span>
                <span>{{ option.name }}</span>
                <span>{{ option.dial_code }}</span>
              </div>
              <div></div>
            </div>
          </template>
        </vue-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

export default {
  name: 'CountryModal',
  props: ['countries', 'selectedCountry'],
  components: {
    VueSelect,
  },
  methods: {
    ...mapMutations('modal', ['SET_WHAT_MODAL']),
    handleCountrySelection(value) {
      this.$emit('countrySelected', value);
    },
  },
};
</script>
