import History from '@/apis/History'

const state = {
  readyOrdersData: [],
  pendingOrdersData: [],
  cancelledOrders: [],
  pendingAndReadyOrders: [],
  servedOrders: [],
}

const getters = {
  getReadyOrders: (state) => state.readyOrdersData,
  getPendingOrders: (state) => state.pendingOrdersData,
  getPendingAndReadyOrders: (state) => state.pendingAndReadyOrders,
  getServedOrders: (state) => state.servedOrders,
  getCancelledOrders: (state) => state.cancelledOrders,
}

const actions = {
  fetchReadyOrders({ commit, dispatch }) {
    dispatch('changeIsLoading', true, {
      root: true,
    })
    History.getReadyOrders().then((response) => {
      commit('SET_READY_ORDERS', response)
      dispatch('changeIsLoading', false, {
        root: true,
      })
    })
  },

  fetchPendingOrders({ commit, dispatch }) {
    dispatch('changeIsLoading', true, {
      root: true,
    })
    History.getPendingOrders().then((response) => {
      commit('SET_PENDING_ORDERS', response)
      dispatch('changeIsLoading', false, {
        root: true,
      })
    })
  },

  fetchPendingAndReadyOrders({ commit, dispatch }) {
    dispatch('changeIsLoading', true, {
      root: true,
    })

    History.getPendingAndReadyOrders().then((response) => {
      commit('SET_PENDING_AND_READY_ORDERS', response)
      dispatch('changeIsLoading', false, {
        root: true,
      })
    })
  },

  fetchCancelledOrders({ commit, dispatch }) {
    dispatch('changeIsLoading', true, {
      root: true,
    })
    History.getCancelOrders().then((response) => {
      commit('SET_CANCEL_ORDERS', response)
      dispatch('changeIsLoading', false, {
        root: true,
      })
    })
  },

  fetchServedOrders({ commit, dispatch }) {
    dispatch('changeIsLoading', true, {
      root: true,
    })

    History.getServedOrders().then((response) => {
      commit('SET_SERVED_ORDERS', response)
      dispatch('changeIsLoading', false, {
        root: true,
      })
    })
  },

  removePendingOrder({ commit, state }, orderId) {
    const updatedOrders = state.pendingOrdersData.filter(
      (order) => order.id !== orderId
    )
    commit('SET_PENDING_ORDERS_AFTER_CANCEL', updatedOrders)
  },
}

const mutations = {
  SET_READY_ORDERS: (state, payload) => {
    state.readyOrdersData = payload.data.data
  },

  SET_PENDING_ORDERS: (state, payload) => {
    state.pendingOrdersData = payload.data.data
  },

  SET_PENDING_ORDERS_AFTER_CANCEL: (state, payload) => {
    state.pendingOrdersData = payload
  },

  SET_PENDING_AND_READY_ORDERS: (state, payload) => {
    state.pendingAndReadyOrders = payload.data.data
  },

  SET_SERVED_ORDERS: (state, payload) => {
    state.servedOrders = payload.data.data
  },

  SET_CANCEL_ORDERS: (state, payload) => {
    state.cancelledOrders = payload.data.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
