/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable space-before-function-paren */
import Vue from 'vue'
import Order from '@/apis/Order'
import Api from '@/apis/Api'
import router from '@/router'
import message from './helper/message'
import user from './user'

// State
const data = localStorage.getItem('cartData') 
var state = {}
if(data) {
   state = JSON.parse(data)
} else {
   state = {
    carts: [],
    cartCheckout: {
      products: [],
      totalPrice: 0,
    },
    emailMember: '',
    invoice: '',
    orderPayload: {
      customer_id: 0,
      type: 'dine-in',
      payment_type: 'fiserv',
      note: 'null',
      platform: 'pos',
      discount: 0,
      discount_type: 'flat',
      deal: '',
      wallet: 0,
      cashback: false,
      products: [
        {
          id: null,
          quantity: 1,
          addons: [],
        },
      ],
    },
    amountToPay: 0,
  }
}

// Getters
const getters = {
  countTotalCart: (state) => {
    return state.carts.length
  },

  getInvoice: (state) => {
    return state.invoice
  },

  cartTotalPrice: (state) => {
    let total = 0

    state.carts.forEach((cart) => {
      let productTotal = cart.product.sale_price * cart.qty

      if (cart.addons && cart.addons.length > 0) {
        cart.addons.forEach((addon) => {
          productTotal += addon.product.sale_price * cart.qty
        })
      }

      total += productTotal
      cart.product.price = productTotal.toFixed(2)
    })

    return total.toFixed(2)
  },

  getAllCarts: (state) => state.carts,
  
  getCartState: (state) => {
    return state
  },

  getOrderPayload: (state) => {
    return state.orderPayload
  },

  getOrderType: (state) => {
    return state.orderPayload.type
  },
  getPaymentType: (state) => {
    return state.orderPayload.payment_type
  },
  getDiscountAmount: (state) => {
    return state.orderPayload.discount
  },

  getDiscountType: (state) => {
    return state.orderPayload.discount_type
  },

  getAmountToPay: (state) => {
    return state.amountToPay
  },
}

// Actions
const actions = {
  getCarts({ commit }, carts) {
    commit('SET_CARTS', carts)
  },

  clearCartsWhenLogout({ commit }) {
    commit('CLEAR_CARTS_WHEN_LOGOUT')
  },

  async createOrder({ commit, dispatch }, data) {
    // dispatch('changeIsLoading', true, { root: true })
    try {
      if (data.payload.discount == 0) {
        data.payload.discount_type = ''
      }
      const response = await Order.placeOrder(data)

      if (response.data.data.status && data.payload.payment_type == 'fiserv') {
        const dataToSend = {
          configuration: data.config,
          order: response.data.data,
          token: localStorage.getItem('token')
        }
        const message = JSON.stringify(dataToSend)
        console.log("message", message)
        if (
          window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage
        ) {
          window.ReactNativeWebView.postMessage(message)
        } else {
          alert('window.ReactNativeWebView.postMessage is not available')
        }
      }

      if (data.payload.payment_type != 'fiserv' && response.data.data.status) {
        message.toastSuccess('Order place successfully!')
      } else {
        message.toastError(response.data.data.message)
      }

      if (response.data.data.status) {
        commit('CLEAR_CART')
      }

      // dispatch('changeIsLoading', false, { root: true })
      return response
    } catch (error) {
      message.toastError('Facing issue while placing order!')
      // dispatch('changeIsLoading', false, { root: true })
      throw error.response
    }
  },

  orderType({ commit }, type) {
    commit('SET_ORDER_TYPE', type)
  },

  paymentType({ commit }, type) {
    commit('SET_PAYMENT_TYPE', type)
  },

  discountType({ commit }, type) {
    commit('SET_DISCOUNT_TYPE', type)
  },

  orderDeal({ commit }, type) {
    commit('SET_ORDER_DEAL', type)
  },

  discountAmount({ commit, dispatch }, amount) {
    commit('SET_DISCOUNT_AMOUNT', amount)
  },

  amountToPay({ commit, dispatch }, total) {
    commit('SET_AMOUNT_TO_PAY', total)
  },

  addOrderNote({ commit }, note) {
    commit('SET_ORDER_NOTE', note)
  },
}

function addonsArraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].id !== arr2[i].id) {
      return false
    }
  }

  return true
}

// Mutations
const mutations = {
  SET_CARTS: (state, carts) => {
    state.carts = carts
  },
  SET_CART_STATE: (state, data) => {
    state = data
  },
  GENERATE_INVOICE: (state) => {
    const d = new Date()
    const day = d.getDate().toString()
    const month = (d.getMonth() + 1).toString()
    const year = d.getFullYear().toString().split('').splice(2, 3).join('')
    const rnd = Math.random(0, 100).toString().substr(14).toString()
    const invoice = day + month + year + rnd
    state.invoice = invoice
  },

  ADD_TO_CART: (
    state,
    { product, addons, qty, addons_id, productWithAddons }
  ) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      state.carts[productInCartIndex].qty += qty
    } else {
      state.carts.unshift({
        product,
        addons,
        qty,
        addons_id,
        productWithAddons,
      })
      // message.toastSuccess('Product added to cart');
    }
  },

  REMOVE_PRODUCT_FROM_CART: (state, id) => {
    state.carts = state.carts.filter((cart) => cart.product.id !== id)
    // message.toastSuccess('Product remove from cart')
  },

  SET_EMAIL_MEMBER: (state, payload) => {
    state.emailMember = payload
  },

  CLEAR_CART: (state) => {
    message.toastSuccess('Cart has been cleaned')
    state.carts = []
    state.orderPayload.deal = ''
    state.orderPayload.discount_type = 'flat'
    state.orderPayload.discount = 0
    localStorage.removeItem('customer_token')
  },

  CLEAR_CARTS_WHEN_LOGOUT: (state) => {
    state.carts = []
    state.orderPayload.deal = ''
    state.orderPayload.discount_type = 'flat'
    state.orderPayload.discount = 0
    localStorage.removeItem('customer_token')
  },

  SAVE_CART_TO_MODAL: (state, { products, price }) => {
    state.cartCheckout.products = products
    state.cartCheckout.totalPrice = price

    state.orderPayload.products = state.cartCheckout.products.map((product) => {
      if (product.addons.length === 0) {
        return {
          id: product.product.id,
          quantity: product.qty,
          addons: [],
        }
      } else {
        return {
          id: product.product.id,
          quantity: product.qty,
          addons: product.addons_id.map((addon) => addon.addon_product_id),
        }
      }
    })
  },

  MIN_QTY_CART: (state, { product, addons }) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      if (state.carts[productInCartIndex].qty > 1) {
        state.carts[productInCartIndex].qty--
      }
    } else {
      state.carts.unshift({ product, addons, qty })
      message.toastSuccess('Product quantity updated!')
    }
  },

  PLUS_QTY_CART: (state, { product, addons }) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      state.carts[productInCartIndex].qty++
    } else {
      state.carts.unshift({ product, addons, qty })
      message.toastSuccess('Product quantity updated!')
    }
  },

  USE_CASHBACK: (state, { product, addons }) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      state.carts[productInCartIndex].qty++
    } else {
      state.carts.unshift({ product, addons, qty })
      message.toastSuccess('Product quantity updated!')
    }
  },

  CHANGE_TOTAL_QTY: (state, { product, qty, addons }) => {
    const productInCartIndex = state.carts.findIndex((item) => {
      return (
        item.product.id === product.id && addonsArraysEqual(item.addons, addons)
      )
    })

    if (productInCartIndex !== -1) {
      if (state.carts[productInCartIndex].qty < 1) {
        state.carts[productInCartIndex].qty = 1
      } else {
        state.carts[productInCartIndex].qty = qty
      }
    }
  },

  SET_ORDER_TYPE: (state, { type }) => {
    state.orderPayload.type = type
  },

  SET_PAYMENT_TYPE: (state, { type }) => {
    state.orderPayload.payment_type = type
  },

  SET_DISCOUNT_TYPE: (state, { type }) => {
    state.orderPayload.discount_type = type
  },

  SET_ORDER_DEAL: (state, { type }) => {
    state.orderPayload.deal = type
  },

  SET_DISCOUNT_AMOUNT: (state, { amount }) => {
    state.orderPayload.discount = parseInt(amount)
  },

  SET_AMOUNT_TO_PAY: (state, { total }) => {
    state.amountToPay = total
  },
  SET_ORDER_NOTE: (state, { note }) => {
    state.orderPayload.note = note
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
