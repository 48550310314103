<template>
  <div class="">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Auth',
};
</script>
