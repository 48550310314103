import Api from './Api';

const BASE_URL = 'auth';
const token = localStorage.getItem('token');

export default {
  login(data) {
    return Api.post(`${BASE_URL}/login`, data);
  },
  customerLogin(data) {
    return Api.post(`/customers/loginOrRegister`, data);
  },
  logout() {
    return Api.post(`${BASE_URL}/logout`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  },
};
