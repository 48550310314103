<template>
  <div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Product Name</th>
          <th scope="col">SKU</th>
          <th scope="col">Available stock</th>
          <th scope="col">Default Quantity</th>
          <th scope="col">Is enabled</th>
          <th scope="col">New</th>
        </tr>
      </thead>
      <tbody v-if="stocks.length > 0">
        <stock-management-data v-for="(stock, key) in stocks" :key="key" :index="key" :product="stock"
          @update-stock="updateStock" />
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="7">No orders found</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { getStock, manageStock } from '../../apis/Product';
import { mapGetters } from 'vuex';
import stockManagementData from './stockManagementData.vue';

export default {
  name: 'StockManagement',
  mixins: [mixins],
  components: {
    stockManagementData,
  },
  data() {
    return {
      stocks: [],
      updatedStocks: [],
    };
  },
  computed: {
    ...mapGetters('user', ['getDetailUser']),
  },
  mounted() {
    this.fetchStock();
  },
  methods: {
    fetchStock() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        token: token,
      };
      getStock(requestData)
        .then((response) => {
          if (response.status) {
            this.stocks = response.data.data.products;
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    updateStock(changedStock) {
      const index = this.updatedStocks.findIndex(stock => stock.product_id === changedStock.product_id);
      if (index !== -1) {
        this.updatedStocks.splice(index, 1, changedStock);
      } else {
        this.updatedStocks.push(changedStock);
      }
    },

    saveAll() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        payload: this.updatedStocks,
        token: token,
      };

      manageStock(requestData)
        .then((response) => {
          console.log(("response", response));
          if (response.status) {
            this.toastSuccess('All stocks saved successfully.');
          } else {
            this.toastError('Some stocks could not be saved.');
          }
        })
        .catch((error) => {
          console.log('Error', error);
          this.toastError('An error occurred while saving stocks.');
        });
    },
  },
};
</script>

<style scoped></style>
