import Product from '@/apis/Product';

// State
const state = () => ({
  products: [],
  product: {
    name: '',
    image: '',
    price: '',
    category: '',
    id: '',
  },
  currentPage: 1,
  totalProduct: 0,
  showSearch: false,
  searchInputText: '',
  ordering: {
    order: 'id',
    sort: 'desc',
  },
});

// Getters
const getters = {
  allProducts: (state) => {
    return state.products;
  },
};

// Actions
const actions = {
  getProducts({ commit, dispatch }) {
    dispatch('changeIsLoading', true, {
      root: true,
    });
    const branchId = localStorage.getItem('branchId');
    Product.all(branchId)
      .then((response) => {
        dispatch('changeIsLoading', false, {
          root: true,
        });
        commit('SET_PRODUCTS', response.data);
      })
      .catch((err) => {
        dispatch('changeIsLoading', false, {
          root: true,
        });
        commit('SET_PRODUCTS', err.response);
      });
  },
};

// Mutations
const mutations = {
  SET_PRODUCTS: (state, data) => {
        if (data.error) {
      state.products = [];
    } else {
      state.products = data.data[0].data;
    }
  },

  UPDATE_SEARCH_INPUT_TEXT: (state, data) => {
    state.searchInputText = data;
  },

  SHOW_PART_SEARCH: (state, data) => {
    state.showSearch = data;
  },

  CHANGE_ORDERING: (state, data) => {
    state.ordering.sort = data.sort;
    state.ordering.order = data.order;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
