<template>
  <g-card :isHeader="false">
    <template #customHeader>
      <div class="px-4 pt-4">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="font-weight-bold">Pending Orders</h5>
        </div>
      </div>
    </template>
    <template #cardBody>
      <PendingOrders />
    </template>
  </g-card>
</template>

<script>
import { mapActions } from 'vuex';
import PendingOrders from '@/components/molecules/PendingOrders';
export default {
  name: 'PendingOrdersCard',
  components: {
    PendingOrders,
  },
  data() {
    return {
      filterVal: 'all',
    };
  },
  methods: {
    ...mapActions('history', ['filterHistory']),
  },
};
</script>

<style scoped></style>
