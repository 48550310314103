const state = {
  selectedCountry: {},
};

const getters = {
  getSelectedCountry: (state) => state.selectedCountry,
};

const mutations = {
  setSelectedCountry: (state, country) => (state.selectedCountry = country),
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
