<template>
  <!-- <div class="col-md-12 px-0">
    <footer class="py-3 bg-light shadow-lg">
      <div class="container-fluid text-center">
        &copy; Teams.pk {{ new Date().getFullYear() }}
      </div>
    </footer>
  </div> -->
  <div></div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style scoped></style>
