<template>
  <g-card :isHeader="false">
    <template #customHeader>
      <div class="px-4 pt-4">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="font-weight-bold">Stock Management</h5>
          <b-button variant="primary" @click="saveAll">Save All</b-button>
        </div>
      </div>
    </template>
    <template #cardBody>
      <StockManagement ref="stockManagementRef" />
    </template>
  </g-card>
</template>

<script>
import StockManagement from '@/components/molecules/StockManagement';

export default {
  name: 'StockManagementCard',
  components: {
    StockManagement,
  },
  methods: {
    saveAll() {
      this.$refs.stockManagementRef.saveAll();
    },
  },
};
</script>

<style scoped></style>
