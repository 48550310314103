const state = {
  whatModal: '',
};

const getters = {
  getWhatModal: (state) => state.whatModal,
};

const mutations = {
  SET_WHAT_MODAL: (state, value) => {
    state.whatModal = value;
  },
  HIDE_MODAL: (state) => {
    state.whatModal = '';
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
