<template>
  <div class="chart-product mb-4">
    <div class="position-relative">
      <div class="img-list-chart">
        <img :src="cart.product.preview_url" :alt="cart.product.name" />
      </div>
      <g-button @cus-click="REMOVE_PRODUCT_FROM_CART(cart.product.id, cart.addons_id)"
        cusClass="text-white delete-chart border-0" size="sm">
        <img class="reset-class" :src="require(`@/assets/images/trash.svg`)" width="30" alt srcset />
      </g-button>
    </div>
    <div class="name-input">
      <div class="d-flex flex-nowrap flex-row justify-content-between">
        <span class="chart-title-product">{{ cart.product.name }}</span>
        <g-button v-if="cart.addons.length > 0" @cus-click="showModal('edit_addons', cart)" v-b-modal.modal-primary>
          <img class="edit-class" :src="require(`@/assets/images/editing.png`)" width="30" alt srcset />
        </g-button>
      </div>
      {{ cart.product.price | currency }}
      <ul>
        <li v-for="addon in addons" :key="addon.id">
          <div class="addon-item">
            <span>{{ addon.product.name }}</span>
            <span class="addon-price">{{ addon.product.sale_price | currency }}</span>
          </div>
        </li>
      </ul>

      <CartInput :qty="cart.qty" :product="cart.product" :addons="cart.addons" />
    </div>
    <EditAddonModal v-if="this.editModalCalling" :whatModal="getWhatModal" :selectedProduct="selectedProduct"
      :editSelectedAddons="editSelectedAddons" :editSelectedAddonsObj="editSelectedAddonsObj"
      :uniqueEditingModal="uniqueEditingModal" :quantity="quantity" />
  </div>
</template>

<script>
import CartInput from './CartInput';
import EditAddonModal from './EditAddonModal.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    CartInput,
    EditAddonModal,
  },
  data() {
    return {
      editModalCalling: false,
      whatModal: '',
      uniqueEditingModal: null,
      selectedProduct: null,
      editSelectedAddons: null,
      editSelectedAddonsObj: null,
      quantity: null,
      addons: []
    };
  },
  props: ['cart'],
  watch: {
    cart(newVal) {
       this.addons = this.cart.addons.sort((a, b) => a.product_modifier_id - b.product_modifier_id)
    }
  },
  mounted() {
    this.addons = this.cart.addons.sort((a, b) => a.product_modifier_id - b.product_modifier_id)
  },

  methods: {
    ...mapMutations('modal', ['SET_WHAT_MODAL']),
    ...mapMutations('cart', ['ADD_TO_CART', 'REMOVE_PRODUCT_FROM_CART']),

    showModal(val, cart) {
      this.uniqueEditingModal = cart.addons_id.map((addon) => addon.addon_id).join('_');
      this.selectedProduct = cart.productWithAddons;
      this.editSelectedAddons = cart.addons_id;
      this.editSelectedAddonsObj = cart.addons;
      this.editModalCalling = true;
      this.quantity = cart.qty;
      this.SET_WHAT_MODAL(val + '_' + this.uniqueEditingModal);
    },
  },
  computed: {
    ...mapGetters('modal', ['getWhatModal']),
  },
};
</script>

<style scoped>
.reset-class {
  width: 20px;
  height: 20px;
  border-radius: 0;
  margin-right: 0;
}

.edit-class {
  width: 20px;
  height: 20px;
  border-radius: 0;
  margin-right: 0;
}

ul {
  list-style: none;
  padding: 0;
}

.addon-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px;
  border-bottom: 1px solid #ccc;
}

.addon-item:last-child {
  border-bottom: none;
}

.addon-price {
  color: #888;
  margin-left: 20px;
}
</style>
