<template>
  <div class="home">
    <b-container fluid>
      <b-row>
        <b-col md="12" class="px-0">
          <div class="content-left history pt-1 pr-4 pb-0">
            <Sidebar />
            <div class="main-left mb-2">
              <!-- <ListInfoHistory /> -->
              <div class="row">
                <div class="col-md-12 mb-5 mt-3">
                  <ReadyOrderCard />
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ListInfoHistory from '@/components/molecules/ListInfoHistory';
import Sidebar from '@/components/layout/MainSidebar';
import ReadyOrderCard from '@/components/organisms/OrderCard';
import PendingOrdersCard from '@/components/organisms/PendingOrdersCard';
import ChartCard from '@/components/organisms/ChartCard';
// @ is an alias to /src
import '@/assets/css/style.css';
export default {
  name: 'History',
  components: {
    Sidebar,
    ReadyOrderCard,
    ChartCard,
    ListInfoHistory,
    PendingOrdersCard
  },
  data() {
    return {};
  },
};
</script>
