<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col">#</th>
        <th scope="col">Code</th>
        <th scope="col">Type</th>
        <th scope="col">Status</th>
        <th scope="col">Platform</th>
        <th scope="col">Payment</th>
        <th scope="col">Discount</th>
        <th scope="col">Discount Type</th>
        <th scope="col">Total</th>
        <th scope="col">Sub Total</th>
        <th scope="col">Note</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody v-if="pendingAndReadyOrders.length > 0">
      <tr v-for="(order, index) in pendingAndReadyOrders" :key="index">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ order.code }}</td>
        <td>{{ order.type }}</td>
        <td>{{ order.status }}</td>
        <td>{{ order.platform }}</td>
        <td>{{ order.payment }}</td>
        <td>{{ order.discount ? order.discount + '%' : 'none' }}</td>
        <td>{{ order.discount_type ? order.discount_type : 'none' }}</td>
        <td>{{ order.total }}</td>
        <td>{{ order.sub_total }}</td>
        <td>{{ order.note }}</td>
        <td>
          <b-button variant="primary" class="mr-2" :disabled="order.status === 'ready'"
            @click="ready(order.id)">Ready</b-button>
          <b-button variant="warning" class="mr-2" :disabled="order.status === 'ongoing'"
            @click="reminder(order.id)">Reminder</b-button>
          <b-button variant="success" class="mr-2" :disabled="order.status === 'ongoing'"
            @click="serve(order.id)">Serve</b-button>

          <b-button variant="danger" @click="cancel(order.id)">Cancel</b-button>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="12">No orders found</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapGetters, mapActions } from 'vuex';
import { readyOrder, cancelOrder, serveOrder, sendReadyOrderReminder } from '../../apis/Order';

export default {
  name: 'TableHistory',
  mixins: [mixins],
  computed: {
    ...mapGetters('history', ['getPendingAndReadyOrders']),
    ...mapGetters('user', ['getDetailUser']),
    pendingAndReadyOrders() {
      return this.getPendingAndReadyOrders;
    },
  },

  methods: {
    ...mapActions('history', ['fetchPendingAndReadyOrders', 'removePendingOrder']),
    ready(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      readyOrder(requestData)
        .then((response) => {
          if (response.status) {
            this.fetchPendingAndReadyOrders();
            this.toastSuccess('Order ready successfully!');
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    cancel(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      cancelOrder(requestData)
        .then((response) => {
          if (response.status) {
            this.fetchPendingAndReadyOrders();
            this.toastSuccess('Order cancelled successfully!');
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },

    serve(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      serveOrder(requestData)
        .then((response) => {
          if (response.status) {
            this.fetchPendingAndReadyOrders();
            this.toastSuccess('Order serve successfully!');
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },

    reminder(id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };
      sendReadyOrderReminder(requestData)
        .then((response) => {
          if (response.status) {
            this.toastSuccess(response.data.message);
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
  },
  mounted() {
    this.fetchPendingAndReadyOrders();
  },
};
</script>

<style scoped></style>
